@import "variables";
main{
    padding-bottom: 100px;
}
#headerLogo {
    width: 30px;
    margin-right: 10px;
}
.bg-explanation{
  background-color: #2f91ed;
}
.pointer{
    cursor: pointer;
}
.explanation {
    cursor: auto;
    position: relative;
    z-index: 1;
    .explanationWrapper {
        max-width: 600px;
        margin: 1.2rem auto;
        padding: 1rem;
        border-radius: 15px;
        background-color: #2f91ed;
        .explanationHeader {
            font-size: 1.2rem;
        }
        .explanationText {
            margin: 0.5rem;
            font-size: 1rem;
        }
        a {
            color: #fff;
        }
    }
    .teach {
        background-color: $homeRed;
        font-size: 1rem;
    }
}
#safeMessage {
    position: absolute;
    z-index: 1;
    top: 0%;
    background-color: #2f91ed;
    color: $white;
    padding: 10px;
    .row {
        align-items: center;
        margin: 0;
    }
    span {
        position: absolute;
        top: 0.5rem;
        font-size: 2rem;
        right: 2rem;
        cursor: pointer;
    }
}

#notification {
    position: absolute;
    top: 10%;
    right: 10px;
    height: 60px;
    width: 275px;
    background-color: #f8f8f8;
    color: black;
    padding: 5px;
    box-shadow: rgb(0 0 0 / 30%) 0px 3px 5px;
    z-index: 1;
    border-radius: 10px;
    animation-name: fadeleft;
    animation-duration: 2s;
}
#notificationIphone {
    position: fixed;
    bottom: 5%;
    width: 90%;
    height: 80px;
    right: 5%;
    background-color: #f8f8f8;
    color: black;
    padding: 5px;
    box-shadow: rgb(0 0 0 / 30%) 0px 3px 5px;
    z-index: 1;
    border-radius: 10px;
    animation-name: fadeleft;
    animation-duration: 2s;
    display: flex;
    img {
        height: 60px;
        margin-top: 5px;
        margin-right: 16px;
    }
}
@keyframes fadeleft {
    from {
        opacity: 0;
        transform: translateX(150px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.btn-homeRed {
    background-color: $homeRed;
    border-color: $homeRed;
    color: $white;
}

.btn-homeRed:hover,.btn-homeRed:active,.btn-homeRed:focus-visible {
    background-color: $red;
    color: $white;
}

#home {
    .btn-homeRed:hover,.btn-homeRed:active,.btn-homeRed:focus-visible {
        background-color: $red;
    }
}
