// Body
$body-bg: #f8fafc;

// Typography
$font-family-sans-serif: 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Collors
$white: #ffffff;
$grey:#90949c;
$black: #333333;
$blue: #3490dc;
$hoverBlue: #2a73b0;
$explanationBlue: #2f91ed;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #ff0000;
$homeRed: #f5587b;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;